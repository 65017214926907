<template>
  <div>
    <p v-if="items.length == 0" class="text-center">
      Aún se han cargado documentos
    </p>
    <div
      v-else
      v-for="i in items" :key="i.key"
      class="container-admin-video"
    >
      <template v-if="i.edit === false">
        <div class="container-list-video">
          <div class="container-video">
            <a :href="i.url" target="_blank">
              <svg 
                version="1.1"
                class="symbol-video-admin"
                xmlns="http://www.w3.org/2000/svg" 
                xmlns:xlink="http://www.w3.org/1999/xlink" 
                x="0px" y="0px"
                viewBox="0 0 97.75 97.75"
                xml:space="preserve"
              >
                <g>
                  <path d="M39.969,59.587c7.334-3.803,14.604-7.571,21.941-11.376c-7.359-3.84-14.627-7.63-21.941-11.447
                    C39.969,44.398,39.969,51.954,39.969,59.587z"/>
                  <path d="M48.875,0C21.883,0,0,21.882,0,48.875S21.883,97.75,48.875,97.75S97.75,75.868,97.75,48.875S75.867,0,48.875,0z
                      M82.176,65.189c-0.846,3.67-3.848,6.377-7.461,6.78c-8.557,0.957-17.217,0.962-25.842,0.957c-8.625,0.005-17.287,0-25.846-0.957
                    c-3.613-0.403-6.613-3.11-7.457-6.78c-1.203-5.228-1.203-10.933-1.203-16.314s0.014-11.088,1.217-16.314
                    c0.844-3.67,3.844-6.378,7.457-6.782c8.559-0.956,17.221-0.961,25.846-0.956c8.623-0.005,17.285,0,25.841,0.956
                    c3.615,0.404,6.617,3.111,7.461,6.782c1.203,5.227,1.193,10.933,1.193,16.314S83.379,59.962,82.176,65.189z"/>
                </g>
              </svg>
            </a>
          </div>
          <div class="desc-video">
            <div class="container-fecha-sesion">
              <div class="container-fecha">
                <p class="fecha">{{ i.fecha }} </p>
                <span class="separador">|</span>
                <p class="hora">{{ i.hora }} hrs.</p>
              </div>
              <template v-if="i.sesion">
                <div class="container-sesion">
                  <span class="sesion">
                    <a :href="`https://congresoqroo.gob.mx/ordenesdia/${i.sesion}`" target="_blank">
                      <span class="sesion-nom-largo">Ir a la Sesión {{ i.sesion }}</span>
                      <span class="sesion-nom-corto">Sesión: {{ i.sesion }}</span>
                    </a>
                  </span>
                </div>
              </template>
              
            </div>
            <p>{{ i.titulo }}</p>
          </div>
        </div>
        <div class="container-actions-video">
          <b-button
            class="button-edit"
            v-b-tooltip.hover
            title="Editar"
            @click="setEditDocument(i.key)"
          >
            <i class="fas fa-pencil-alt"></i>
          </b-button>
          <b-button
            class="button-delete"
            @click.stop="deleteDocument(i.key)"
            v-b-tooltip.hover
            title="Eliminar"
          >
            <i class="far fa-trash-alt"></i>
          </b-button>
        </div>

      </template>
      <div v-else class="container-edit">
        <div class="container">
          <div class="form-row">
            <div class="col-12">
              <b-form-input
                v-model.trim="i.titulo"
                type="text"
                placeholder="Título del Video"
              ></b-form-input>
            </div>
            <div class="col-12 col-md-4">
              <b-form-input
                v-model="i.fecha"
                type="date"
                placeholder="Fecha del Video"
              ></b-form-input>
            </div>
            <div class="col-6 col-md-4">
              <b-form-input
                v-model.trim="i.hora"
                type="time"
                placeholder="Hora del Video"
              ></b-form-input>
            </div>
            <div class="col-6 col-md-4">
              <b-form-input
                v-model="i.sesion"
                type="number"
                placeholder="Agrege el ID de la Sesión"
              ></b-form-input>
            </div>
            <div class="col-12">
              <b-form-input
                v-model.trim="i.url"
                type="url"
                placeholder="Ingresa la URL"
              ></b-form-input>
            </div>
          </div>
          <b-button class="button-accept" @click="setUpdateDocument(i)">
            Actualizar Video
          </b-button>
          <b-button class="button-cancel" @click="cancelEditDocument(i.key)">
            Cancelar
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../firebase";

export default {
  props: ["docto"],
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.docto)
        .collection("documentos"),
      items: {},
      showEdit: true,
      enabled: false,
    };
  },
  created() {
    this.ref.orderBy("fecha", "desc").onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          ...doc.data(),
        });
      });
      //console.log(this.items)
    });
  },
  methods: {
    setEditDocument(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: true,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al Actuaizar el documento: ", error);
        });
    },
    setUpdateDocument(id) {
      const updateRef = this.ref.doc(id.key);
      updateRef
        .update({
          titulo: id.titulo,
          fecha: id.fecha,
          hora: id.hora,
          sesion: id.sesion,
          url: id.url,
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
          this.enabled === false;
        })
        .catch((error) => {
          alert("Error al Actuaizar el documento: ", error);
        });
    },
    cancelEditDocument(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al cancelar la Edición del Documento: ", error);
        });
    },
    deleteDocument(id) {
      this.$swal({
        title: "¿Realmente quiere eliminar este Documento?",
        text: "¡Una vez eliminado ya no podrá recuperar la información!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#657f61",
        cancelButtonColor: "#99494b",
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "No, cancelar",
        //closeOnCancel: true
      }).then((result) => {
        //send request to server
        if (result.isConfirmed) {
          firebase
            .firestore()
            .collection("secciones")
            .doc(this.docto)
            .collection("documentos")
            .doc(id)
            .delete()
            .then(() => {
              //console.log("Eliminado")
            })
            .catch((error) => {
              alert("Error al eliminar el documento: ", error);
            });
          this.$swal(
            "Registro Eliminado",
            "El registro ha sido eliminado con éxito.",
            "success"
          );
        }
      });
    },
    getURLFile: function(url) {
      this.urlFile = url;
    },
  },
};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";

  .container-admin-video {
    display: flex;
    padding: 0.5rem 0;
    @include media-breakpoint-up(md) {
      padding: 0.5rem 1rem;
    }
    &:hover {
      background: $color-bg-01-transp;
    }

    .container-list-video {
      width: 90%;
      display: flex;
      padding: 0.5rem;
      .container-video {
        width: 3rem;
        a {
          .symbol-video-admin {
            width: 2.5rem;
            height: 2.5rem;
            margin-right: 0.5rem;
            fill: $color-text;
          }
          :hover {
              fill: $color-bg-01;
            }
        }
      }
      .desc-video {
        width: 90%;
        .container-fecha-sesion {
          display: flex;
          .container-fecha {
            @include media-breakpoint-up(md) {
              display: flex;
            }
            p {
              margin-bottom: 0;
              line-height: 1.2;
              font-size: 0.85rem;
              @include media-breakpoint-up(md) {
                display: flex;
                font-size: 1rem;
              }
            }
            .fecha {
              font-weight: 700;
              font-size: 0.75rem;
              color: $color-bg-01;
            }
            .separador {
              display: none;
              @include media-breakpoint-up(md) {
                display: block;
                line-height: 1;
                margin: 0 0.25rem;
              }
            }
            .hora {
              font-weight: 400;
              font-size: 0.75rem;
            }
          }
          .container-sesion {
            padding: 0 0.6rem;
            line-height: 1.2;
             @include media-breakpoint-up(md) {
              padding: 0 1.5rem;
            }
            .sesion {
              font-size: 0.7rem;
              font-weight: 700;
              a {
                background: $color-bg-03;
                border-radius: 10px;
                color: $color-text;
                text-decoration: none;
                padding: 0 0.7rem;
                @include media-breakpoint-up(md) {
                  padding: 0 1rem;
                }
                &:hover {
                  background: $color-text;
                  color: $color-bg-03
                }
                .sesion-nom-largo {
                  display: none;
                  @include media-breakpoint-up(md) {
                    display: inline-block;
                  }
                }
                .sesion-nom-corto {
                  display: inline-block;
                  @include media-breakpoint-up(md) {
                    display: none;
                  }
                }
              }
            }
          }
        }
        p {
          font-size: 0.85rem;
          @include media-breakpoint-up(md) {
           font-size: 1rem;
          }
        }
      }
    }
    .container-actions-video {
      width: 60px;
      padding: 0.5rem;

      @include media-breakpoint-up(md) {
        width: 120px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  
  
</style>

