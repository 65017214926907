<template>
  <b-list-group>
    <p v-if="avisos.length == 0" class="text-center">
      Aún no se han publicado Avisos.
    </p>
    <b-list-group-item
      v-else
      v-for="a in avisos"
      :key="a.key"
      class="d-flex align-items-center list-documents"
    >
      <template v-if="a.edit === false">
        <span class="indicaStatus">
          <i v-if="a.activo == true" class="far fa-check-circle activo"></i>
          <i v-else class="far fa-times-circle desactivo"></i>
        </span>
        <span class="mr-auto">
          <div class="text-aviso" v-html="a.aviso"></div>
        </span>
        <b-button
          class="button-edit"
          v-b-tooltip.hover
          title="Editar"
          @click="setEditAviso(a.key)"
        >
          <i class="fas fa-pencil-alt"></i>
        </b-button>

        <b-button
          class="button-delete"
          @click.stop="deleteAviso(a.key)"
          v-b-tooltip.hover
          title="Eliminar"
        >
          <i class="far fa-trash-alt"></i>
        </b-button>
      </template>
      <div v-else class="container-edit">
        <transition name="fade">
          <div class="container">
            <div class="form-row">
              <div class="col-12">
                <vue-editor id="aviso" v-model="a.aviso" />
              </div>
              <div class="col-4">
                <p>¿Activar Aviso?</p>
                <switches v-model="a.activo" color="blue"></switches>
              </div>
            </div>
            <b-button class="accept" @click="setUpdateAviso(a)">
              Guardar Aviso
            </b-button>
            <b-button
              v-b-tooltip.hover
              title="Cancelar Edición"
              @click="cancelEditAviso(a.key)"
            >
              Cancelar
            </b-button>
          </div>
        </transition>
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import firebase from "../firebase";
import router from "../router";
import Switches from "vue-switches";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
    Switches,
  },
  data() {
    return {
      ref: firebase.firestore().collection("avisos"),
      avisos: {},
      enabled: false,
    };
  },
  created() {
    this.ref.onSnapshot((querySnapshot) => {
      this.avisos = [];
      querySnapshot.forEach((doc) => {
        this.avisos.push({
          key: doc.id,
          aviso: doc.data().aviso,
          activo: doc.data().activo,
          edit: doc.data().edit,
        });
      });
      //console.log(this.items)
    });
  },
  methods: {
    setEditAviso(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: true,
        })
        .then(() => {
          //console.log("Se Actualizó la sección")
        })
        .catch((error) => {
          alert("Error al actualizar la sección. ", error);
        });
    },
    setUpdateAviso(id) {
      const updateRef = this.ref.doc(id.key);
      updateRef
        .update({
          aviso: id.aviso,
          activo: id.activo,
          edit: false,
        })
        .then(() => {
          this.enabled = false;
        })
        .catch((error) => {
          alert("Error al Actualizar el documento. ", error);
        });
    },
    cancelEditAviso(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al cancelar la Edición del Documento: ", error);
        });
    },
    deleteAviso(id) {
      this.$swal({
        title: "¿Realmente quiere eliminar este Aviso?",
        text:
          "¡Esto eliminará el aviso, una vez eliminado ya no podrá recuperar la información!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#657f61",
        cancelButtonColor: "#99494b",
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "No, cancelar",
        //closeOnCancel: true
      }).then((result) => {
        //send request to server
        if (result.isConfirmed) {
          firebase
            .firestore()
            .collection("avisos")
            .doc(id)
            .delete()
            .then(() => {
              //console.log("Eliminado")
            })
            .catch((error) => {
              alert("Error removing document: ", error);
            });
          this.$swal(
            "Registro Eliminado",
            "El registro ha sido eliminado con éxito.",
            "success"
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";
.indicaStatus {
  i {
    font-size: 1.5rem;
  }
}
.text-aviso {
  padding: 0 1rem;
  font-size: 1rem;
  color: $color-text;
  font-weight: 400;
}
.activo {
  color: $accept;
}
.desactivo {
  color: $delete;
}
</style>
