import firebase from "firebase/app";

import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const settings = { timestampsInSnapshots: true };

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB00eEvR0GBMNSpaa0mq5uaPfhXeKY4AF4",
  authDomain: "web-maya-8f0d6.firebaseapp.com",
  projectId: "web-maya-8f0d6",
  storageBucket: "web-maya-8f0d6.appspot.com",
  messagingSenderId: "988168807102",
  appId: "1:988168807102:web:b8c3d2762f5b498980b4ef",
  measurementId: "G-91CZR9W928"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings(settings);

export default firebase;


