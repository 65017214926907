<template>
  <div class="container">
    <b-form @submit.prevent="onSubmit">
      <div class="form-row">
        <div class="col-12">
          <b-form-input
            v-model.trim="doc.titulo"
            type="text"
            placeholder="Título del video"
          ></b-form-input>
        </div>
        <div class="col-12 col-md-4">
          <b-form-input
            v-model="doc.fecha"
            type="date"
            placeholder="Fecha"
          ></b-form-input>
        </div>
        <div class="col-6 col-md-4">
          <b-form-input
            v-model="doc.hora"
            type="time"
            placeholder="Hora"
          ></b-form-input>
        </div>
        <div class="col-6 col-md-4">
          <b-form-input
            v-model.number="doc.sesion"
            type="number"
            placeholder="Ingresa el ID de la Sesión (opcional)"
          ></b-form-input>
        </div>
        <div class="col-12">
          <b-form-input
            v-model.trim="doc.url"
            type="url"
            placeholder="Ingresa la URL del video"
          ></b-form-input>
        </div>
      </div>
      <b-button class="accept" v-if="doc.titulo && doc.fecha && doc.hora && doc.url" type="submit">
        Guardar Documento
      </b-button>
    </b-form>
  </div>
</template>

<script>
import firebase from "../firebase";

export default {
  name: "NewDocument",
  props: ["docto", "typePub"],
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.docto)
        .collection("documentos"),
      doc: {},
      enabled: false,
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      (this.doc.edit = false);

      this.ref
        .add(this.doc)
        .then((docRef) => {
          (this.doc.titulo = ""),
          (this.doc.fecha = ""),
          (this.doc.hora = ""),
          (this.doc.sesion = ""),
          (this.doc.url = ""),
          (this.edit = false);
        })
        .catch((error) => {
          alert("Error al agregar el documento: ", error);
        });
    }
  },
};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";
.container-switch {
  text-align: center;
  p {
    margin-top: 0.5rem;
  }
}
</style>
