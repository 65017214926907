<template>
  <div>
    <b-modal
      ref="modal-avisos"
      size="lg"
      hide-footer
      hide-header
      title="Avisos"
    >
      <div class="d-block">
        <img
          class="logocongresomodal"
          src="../assets/logos/escudo-XVII-Leg-gris.svg"
          alt=""
        />
        <h5 class="titulo-modal">Avisos</h5>
        <div class="container container-avisos">
          <div
            class="aviso shadow-sm"
            v-for="a in avisos"
            :key="a.key"
            v-html="a.aviso"
          ></div>
        </div>
      </div>
    </b-modal>
    <div class="container-fluid container-welcome">
      <div class="container welcome">
        <div class="row">
          <div class="col-12 col-sm-6 container-title d-flex align-items-center justify-content-center">
            <h1 class="title-welcome">
              <span class="text-1">K k'amike'ex ti' le mejen kúuchil ich maya t'aan ti' le</span>
              <span class="text-2">U Nojpáajtalil <br>A' A'almajt'aanile' <br>Ti' u péetlu'umil <br>Quintaana Roo</span>
              <span class="text-3">H. XVII Legislatura Constitucional</span>
            </h1>
          </div>
          <div class="col-12 col-sm-6 container-symbol-maya">
          </div>
        </div>
      </div>
    </div>

    <div class="container container-link-seccions">
      <div class="row justify-content-center">
        <div class="col-6 col-md-4" v-for="i in items" :key="i.key">
          <div class="box-link-seccion text-center">
            <a v-if="i.tipo == 1" :href="i.url" target="_blank">
              <img
                class="logo-seccion"
                :src="i.logo"
                :alt="'Logo de la sección' + i.name"
              />
              <h5>{{ i.name }}</h5>
            </a>
            <router-link v-if="i.tipo == 2" :to="'/seccion/' + i.key">
              <img
                class="logo-seccion"
                :src="i.logo"
                :alt="'Logo de la sección' + i.name"
              />
              <h5>{{ i.name }}</h5>
            </router-link>
            <p>{{ i.description }}</p>
          </div>
        </div>
      </div>
    </div>

    
  </div>
</template>

<script>
import firebase from "../firebase";
//import router from "../router";

export default {
  name: "Home",
  data() {
    return {
      show: false,
      ref: firebase.firestore().collection("secciones"),
      refAvisos: firebase.firestore().collection("avisos"),
      items: {},
      avisos: {},
      modal: false,
    };
  },
  created() {
    this.ref.orderBy("orden").onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          name: doc.data().name,
          description: doc.data().description,
          tipo: doc.data().tipo,
          logo: doc.data().logo,
          url: doc.data().url,
        });
      });
    });

    this.refAvisos.where("activo", "==", true).onSnapshot((querySnapshot) => {
      this.avisos = [];
      querySnapshot.forEach((doc) => {
        this.avisos.push({
          key: doc.id,
          aviso: doc.data().aviso,
        });
      });

      if (this.avisos.length != 0) {
        this.showModal();
      }
    });
  },
  methods: {
    showModal() {
      this.$refs["modal-avisos"].show();
    },
  },
};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";

.container-welcome {
  background-color: $color-bg-01-transp;
}
.welcome {
  height: auto;
  @include media-breakpoint-up(md) {
    //height: 500px;
  }

  .container-title {
    height: 160px;
    @include media-breakpoint-up(md) {
      height: 320px;
    }
    @include media-breakpoint-up(lg) {
      height: 450px;
    }

    .title-welcome {
      font-family: $font-01;
      color: $color-text;
      width: auto;
      text-align: center;
      margin: 0 auto;
      display: inline-grid;
      @include media-breakpoint-up(md) {
        width: 400px;
        text-align: left;
      }
      @include media-breakpoint-up(lg) {
        margin-top: -2rem;
      }
      .text-1 {
        font-family: $font-02;
        font-size: 0.75rem;
        font-weight: 700;
        color: $color-bg-01;
        text-transform: uppercase;
        margin-bottom: 1rem;
        @include media-breakpoint-up(lg) {
          font-size: 0.8rem;
        }
      }
      .text-2 {
        font-size: 0.9rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 1rem;
        @include media-breakpoint-up(md) {
          font-size: 1.5rem;
        }
        @include media-breakpoint-up(lg) {
          font-size: 2rem;
        }
      }
      .text-3 {
        font-size: 0.85rem;
        font-weight: 400;
        @include media-breakpoint-up(md) {
          font-size: 1rem;
        }
      }
    }
  }

  .container-symbol-maya {
    background-image: url(../assets/logos/vector_maya_01.svg), url(../assets/logos/ornato-maya-01.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: top center, center right 2rem;
    background-size: 130px, 4px;
    height: 150px;

    @include media-breakpoint-up(md) {
      background-position: center center, center right 2rem;
      background-size: 250px, 7px;
      height: 320px;
    }
    @include media-breakpoint-up(lg) {
      background-size: 350px, 9px;
      height: 450px;
    }
  }
}

.container-link-seccions {
  margin-top: 2rem;
  margin-bottom: 3rem;

  .box-link-seccion {
    //max-width: 100px;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      max-width: 250px;
    }

    a {
      cursor: pointer;
      color: $color-text;
      text-decoration: none;

      h5 {
        font-family: $font-01;
        font-size: 1rem;
        font-weight: 300;
        color: $color-bg-01;
        margin-top: 0.75rem;
        @include media-breakpoint-up(md) {
          font-size: 1.2rem;
        }
      }

      &:hover {
        h5 {
          color: $color-text;
        }
      }
      .logo-seccion {
        height: 70px;
        @include media-breakpoint-up(md) {
          height: 100px;
        }
      }
    }
    p {
      font-size: 0.6rem;
      color: $color-text;
      @include media-breakpoint-up(md) {
        font-size: 0.75rem;
      }
    }
  }
}
</style>
