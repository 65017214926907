<template>
  <div id="app">
    <PubHeader />
    <div class="container-router-view">
      <router-view />
    </div>
    <PubFooter />
  </div>
</template>

<script>
import PubHeader from "@/components/PubHeader";
import PubFooter from "@/components/PubFooter";

export default {
  components: {
    PubHeader,
    PubFooter,
  },
};
</script>

<style lang="scss" scope>
@import "scss/abstracts/variables";
  #app {
    background-image: url(assets/logos/vector_maya_02.svg);
    background-repeat: no-repeat;
    background-position: top 300px left -150px;
    background-size: 300px;

    @include media-breakpoint-up(md) {
      background-position: top 200px left -350px;
      background-size: 700px;
    }
    @include media-breakpoint-up(lg) {
      background-position: top 300px left -300px;
      background-size: 800px;
    }
  }
  
</style>